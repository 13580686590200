<template>
<div class="top-box" v-if="id">
    <span class="inline"></span>
    <span class="text">课程列表-编辑课题</span>
</div>
<a-alert
  v-if="id"
  message="该页面操作不是实时保存，修改数据后记得点击保存按钮保存数据！"
  banner
  closable
/>
<div class="box-shadow exercise-box">
  <div class="flex">
    <div class="box-table">
      <a-table
        class="table-box"
        bordered
        :data-source="dataSource"
        :columns="columns"
        :pagination="pagination"
        :row-selection="{...rowSelection}"
        @change="change"
        rowKey="id"
        >
        <template #title>
          <div class="mb-10">全部习题{{total}}</div>
          <div class="top-flex">
            <a-select
              v-model:value="schoolId"
              @change="fetchGetWikiList"
              style="width: 100%;margin-right:10px;"
              placeholder="请选择学校"
              :getPopupContainer='(triggerNode) => triggerNode.parentNode'
            >
              <a-select-option :value="item.id" v-for="item in schoolList" :key="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-input-search v-model:value="search" placeholder="请输入ID、习题名称搜索" @search="fetchSearchGetWikiList" allowClear/>
          </div>
        </template>
        <template #type="{record}">
          <span>{{getTypeName(record.type)}}</span>
        </template>
        <!-- <template #actionId="{ record }">
          <a style="cursor: pointer;" @click="goEditor(record.id)">{{record.id}}</a>
        </template> -->
      </a-table>
    </div>
    <div class="box-table">
      <a-table
        bordered
        :data-source="checkDataSourse"
        :columns="columns_all"
        rowKey="id"
        :pagination="false"
        :customRow="customRow"
        :scroll="{y: 331}">
        <template #title>
          <div class="table-text">已选习题{{checkDataSourse.length}}</div>
        </template>
        <template #type="{record}">
          <span>{{getTypeName(record.type)}}</span>
        </template>
        <!-- <template #actionId="{ record }">
          <a style="cursor: pointer;" @click="goEditor(record.id)">{{record.id}}</a>
        </template> -->
         <template #action1="{record}">
            <a-button style="height:20px;" type="link" size="small" class="col-red" @click.prevent="deleteItem(record)"><DeleteOutlined /></a-button>
         </template>
      </a-table>
    </div>
  </div>
  <a-button type="primary" @click="saveSubmit">保存</a-button>
</div>
</template>

<script>
import { exerciseList, fetchSchoolList, getCourseExercise, addCourseExercise } from '@/api/index'
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
// import draggable from 'vuedraggable'
import { EXERISE } from '@/hooks/config'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: { DeleteOutlined },
  props: ['id'],
  setup (props, ctx) {
    const router = useRouter()
    const dataSource = ref([])
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 70
        // slots: { customRender: 'actionId' }
      },
      {
        title: '题干',
        dataIndex: 'text',
        key: 'text',
        // width: '30%',
        ellipsis: true
      },
      {
        title: '习题标签',
        dataIndex: 'tag',
        key: 'tag',
        width: 100
      },
      {
        title: '习题类型',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        slots: { customRender: 'type' }
      }
    ]
    const columns_all = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 70
        // slots: { customRender: 'actionId' }
      },
      {
        title: '题干',
        dataIndex: 'text',
        key: 'text',
        ellipsis: true
      },
      {
        title: '习题类型',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        slots: { customRender: 'type' }
      },
      {
        title: '',
        key: 'action',
        slots: { customRender: 'action1' },
        align: 'right',
        width: 65
      }
    ]
    const paramObj = reactive({
      paginations: {
        page: 1,
        pagesize: 10
      },
      total: 0,
      search: '',
      schoolId: null,
      typeId: 1, // 选择题
      schoolList: []
    })
    const getTypeName = (type) => {
      return EXERISE.filter(item => {
        return item.id === type
      })[0].name
    }
    // 获取单元列表
    const fetchUnitList = () => {
      fetchSchoolList().then(res => {
        paramObj.schoolList = res.data
        paramObj.schoolList.unshift({ id: 0, name: '全部' })
      })
    }
    const _checkDataSourse = ref([])
    const rowSelection = reactive({
      columnWidth: 50,
      selectedRowKeys: [], // 指定选中项的 key 数组
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        if (_checkDataSourse.value.length === 0) {
          _checkDataSourse.value = selectedRows
        } else {
          _checkDataSourse.value.push(...selectedRows.filter(item => !_checkDataSourse.value.find(items => items.id === item.id)))
        }
        _checkDataSourse.value = _checkDataSourse.value.filter(item => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const checkDataSourse = computed({
      get: () => {
        const _list = _checkDataSourse.value.map(item => {
          const _item = { ...item }
          return _item
        })
        return _list
      },
      set: (value) => {
        _checkDataSourse.value = value
      }
    })
    // 初始化数据
    const fetchCourseExercise = () => {
      getCourseExercise({
        course_id: parseInt(props.id)
      }).then(res => {
        console.log(res.data)
        _checkDataSourse.value = res.data
        rowSelection.selectedRowKeys = res.data.map(item => {
          if (item.id) {
            return item.id
          }
        })
      })
    }
    // 获取百科资源列表
    const fetchGetWikiList = () => {
      exerciseList(Object.assign(paramObj.paginations, {
        search: paramObj.search,
        sid: paramObj.schoolId,
        type: paramObj.typeId
      })).then(res => {
        dataSource.value = res.data
        paramObj.total = res.result
      })
    }
    // 获取分页
    const pagination = computed(() => {
      return {
        current: paramObj.paginations.page,
        pageSize: paramObj.paginations.pagesize,
        total: paramObj.total
      }
    })
    // 分页等改变数据
    const change = (pagination) => {
      paramObj.paginations.page = pagination.current
      fetchGetWikiList()
    }
    // 删除项
    const deleteItem = (item) => {
      const _index = rowSelection.selectedRowKeys.findIndex(items => items === item.id)
      rowSelection.selectedRowKeys.splice(_index, 1)
      _checkDataSourse.value = _checkDataSourse.value.filter(items => items.id !== item.id)
    }
    // 重置数据
    const resetData = () => {
      _checkDataSourse.value = []
      rowSelection.selectedRowKeys = []
    }
    const fetchDrag = () => {
      ctx.emit('drag', _checkDataSourse.value.map(item => item.id))
    }
    // 拖动排序
    let startIndex = 0
    let endIndex = 0
    let startValue = {}
    let endValue = {}
    const customRow = (record, index) => {
      return {
        // 开始拖拽
        ondragstart: (event) => {
          const ev = event || window.event
          ev.stopPropagation()
          startIndex = index
          startValue = record
          console.log(index, '拖拽开始')
        },
        ondragover: (event) => {
          const ev = event || window.event
          ev.preventDefault()
          console.log('拖拽中')
        },
        ondrop: (event) => {
          const ev = event || window.event
          ev.stopPropagation()
          console.log(index, '目标元素')
          endIndex = index
          endValue = record
          _checkDataSourse.value[startIndex] = endValue
          _checkDataSourse.value[endIndex] = startValue
          fetchDrag()
        },
        onmouseenter: (event) => {
          const ev = event || window.event
          ev.target.draggable = true
        }
      }
    }
    const goEditor = (id) => {
      router.push({ name: 'CreateEnc', params: { id } })
    }
    const fetchSearchGetWikiList = () => {
      paramObj.paginations.page = 1
      fetchGetWikiList()
    }
    // 保存
    const saveSubmit = () => {
      addCourseExercise({
        course_id: parseInt(props.id),
        exercises: checkDataSourse.value.map((item) => item.id)
      }).then(res => {
        message.success('保存成功')
      })
    }
    onMounted(() => {
      fetchCourseExercise()
      fetchGetWikiList()
      fetchUnitList()
    })
    return {
      goEditor,
      dataSource,
      columns,
      pagination,
      rowSelection,
      change,
      checkDataSourse,
      deleteItem,
      resetData,
      ...toRefs(paramObj),
      fetchGetWikiList,
      columns_all,
      customRow,
      fetchSearchGetWikiList,
      EXERISE,
      getTypeName,
      saveSubmit
    }
  }
})
</script>

<style lang="scss" scoped>
.top-box{
  width: 200px;
}
.exercise-box{
  padding: 20px;
}
::v-deep .ant-table-thead > tr > th, ::v-deep .ant-table-tbody > tr > td{
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-check{
  flex-wrap: wrap;
  user-select: none;
  max-height: 330px;
  overflow-y: auto;
  .wd-25{
    position: relative;
    cursor: pointer;
    border: 1px solid rgba($color: #000000, $alpha: .2);
    margin-bottom: 5px;
    flex: 0 0 24%;
    padding-left: 5px;
    margin-right: 1%;
    &:hover{
      .delete-icon{
        display: block;
      }
    }
    .delete-icon{
      display: none;
      position: absolute;
      top: -1px;
      right: -1px;
      font-size: 20px;
      color: royalblue;
    }
  }
}
.flex{
  justify-content: space-between;
  .box-table{
    width:48%;
  }
  .col-red{
    color: red;
  }
}
.mb-10{
  margin-bottom: 10px;
}
.table-text{
  height: 64px;
}
::v-deep .ant-table{
  height: 476px;
  .ant-empty-normal {
    margin: 114px 0 !important;
  }
}
.top-flex{
  display: flex;
  align-items: center;
  .mg-r-l{
    margin:0 15px;
  }
}
.table-box{
  table-layout: fixed;
}
</style>
